import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserValidation} from '../shared/user-validation';
import {CardStoreService} from '../shared/card-store.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'em-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy{
  /**
   * Manages the login process for the user
   *
   * Emits the UserLoginEvent via the CardStoreService to which
   * other components are subscribed to (e.g. HomeComponent or
   * LeftMenuComponent). Uses the CardStoreService to validate
   * the user with the provided credentials
   *
   * Login procedure is implemented as follows:
   * App submits user credentials (userName+userToken) to the CMS.
   * CMS checks if the user is part of an organization which has
   * carddecks unlocked. If so the ids of the carddecks are submitted
   * as well as the current api token to retrieve the carddeck information
   * from the CMS. With this implementation the api token can be changed
   * anytime in the CMS without redistribute the angular app.
   *
   * Note: The api for the carddeck information has its own api token
   * which can be different from the general api token used the CMS.
   */

  private subscriptions = new Subscription();
  @Output() submitLogin = new EventEmitter<UserValidation>();

  constructor(
    private cs: CardStoreService,
    private oidcSecurityService: OidcSecurityService) { }

  ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

  ngOnInit(): void {
    this.subscriptions.add(this.oidcSecurityService.userData$.subscribe((userDataResult) => {
        console.log('userDataResult::', userDataResult);
        if ( userDataResult != null) {
          if ( userDataResult.userData != null ) {
            const userName = userDataResult.userData.name;
            const userToken = 1234; // userDataResult.userData.PAT;

            const userValidation: UserValidation = {
              userName,
              userOrganisation: '',
              userToken,
              accessToken: 0,
              carddecks: []
            };
            const userValidation$ = new BehaviorSubject<UserValidation>(userValidation);
            // const userValidation$ = this.cs.validateUser(userValidation);
            this.cs.emitUserLoginEvent(userValidation$);
          }
        }
      })
    );
  }

  /**
   * Oauth Connection for OpenIdConnect
   */
  login() {
    this.oidcSecurityService.authorize();
  }
}
