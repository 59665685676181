<div class="sidenav_container"  [@onSideNavChange]="sideNavState ? 'open' : 'close'">
  <div fxLayout="column" fxLayoutGap="10px" style="height: 100%;">
    <div>
      <mat-nav-list>
        <ng-container *ngFor="let page of pages">
          <a mat-list-item [routerLink]="[page.link]" routerLinkActive="activeLink">
            <mat-icon style="color: black;">
              <i class="material-icons md-32">{{page.icon}}</i>
            </mat-icon>
            <span class="menu_label" [@animateText]="linkText ? 'show' : 'hide'">{{ page?.name }} </span>
          </a>
        </ng-container>
        <ng-container *ngIf="userValidation$ | async as userValidation">
          <div class="carddeck_container">
            <!--ng-container *ngFor="let cardDeck of userValidation.carddecks">
              <a mat-list-item [routerLink]="['carddeck', cardDeck.id]" routerLinkActive="activeLink">
              <mat-icon style="color: black;">
                <i class="material-icons md-32">view_module</i>
              </mat-icon>
              <span class="menu_label" [@animateText]="linkText ? 'show' : 'hide'">{{ cardDeck.name }} </span>
              </a>
            </ng-container-->
          </div>
          <a mat-list-item (click)="logout()" [routerLink]="['home']">
            <mat-icon style="color: black;">
              <i class="material-icons md-32">logout</i>
            </mat-icon>
            <span class="menu_label" [@animateText]="linkText ? 'show' : 'hide'">Logout</span>
          </a>
        </ng-container>
      </mat-nav-list>
    </div>
  </div>
</div>
