<ng-container *ngIf="userValidation$ | async as userValidation">
  <ng-container *ngIf="isOnActiveRoute('carddeck')">
    <button mat-icon-button [color]="getColor(attributionType.CONTROL_LOOP)" class="menu_icon" (click)="updateAttribution(attributionType.CONTROL_LOOP)">
      <mat-icon [inline]="true">border_outer</mat-icon>
    </button>
    <button mat-icon-button [color]="getColor(attributionType.CONTROL_QUESTION)" class="menu_icon" (click)="updateSubAttribution(attributionType.CONTROL_QUESTION)">
      <mat-icon [inline]="true">border_inner</mat-icon>
    </button>
  </ng-container>
</ng-container>
