import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DesignRecommenderRoutingModule } from './design-recommender-routing.module';
import {RecommendationComponent} from './recommendation/recommendation.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import { ElementAddComponent } from './element-add/element-add.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { RecommendedRelationDirective } from './shared/recommended-relation.directive';
import { RelationAddComponent } from './relation-add/relation-add.component';
import { RecommendationRequestComponent } from './recommendation-request/recommendation-request.component';
import {MatIconModule} from '@angular/material/icon';
import { RecommendedRelationsDirective } from './shared/recommended-relations.directive';
import { AddedGameElementDirective } from './shared/added-game-element.directive';


@NgModule({
  declarations: [
    RecommendationComponent,
    ElementAddComponent,
    RecommendedRelationDirective,
    RelationAddComponent,
    RecommendationRequestComponent,
    RecommendedRelationsDirective,
    AddedGameElementDirective
  ],
  imports: [
    CommonModule,
    DesignRecommenderRoutingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatIconModule
  ],
  exports: [
    ElementAddComponent,
    RelationAddComponent,
    RecommendedRelationDirective,
    RecommendationRequestComponent,
    RecommendedRelationsDirective,
    AddedGameElementDirective
  ]
})
export class DesignRecommenderModule { }
